body.mhg {
    #callTA {
        border-color: $f-gold;
    }

    &.horizon-header{
        main > .content{
			margin-top: 177px;
			
			@include desktop {
				margin-top:0px;
			}
        }
		
		&.has-event-banner {
			main > .content{
				margin-top:258px;
			
				@include desktop {
					margin-top:0;
				}
			}
			
			.site-container.no-banner {
				main > .content {
					// Not Scrolled without banner
					> section {
						&:first-child,
						&:first-of-type {
							@extend .section--ptt;
							@include desktop {
								padding-top:300px !important;
							}
						}
					}
				}
			}
		}
		
		&.hide-booking-mask {
			main > .content{
				margin-top: 99px;
				
				@include desktop {
					margin-top:0px;
                    padding-top: 90px;
				}
			}

            .site-container.no-banner {
                main > .content {
                    // Not Scrolled without banner
                    > section {
                        &:first-child,
                        &:first-of-type {
                            @extend .section--ptt;
                            
                            @include desktop {
                                padding-top: unset !important;
                            }
                        }
                    }
                }
            }
			
			&.has-event-banner {
				main > .content{
					margin-top:174px;
					
					@include desktop {
						margin-top:80px;
					}
				}
			}
            .section--ptt {
                padding-top: unset !important; 
            }
            .is-scrolled {
                .tabs.is--sticky,
                .tabs__fallback.is--sticky {
                    top: 69px !important;
                    margin: 0;
                    @include desktop {
                        top: 60px !important;
                    }
                }
            }
            .tabs.is--sticky,
            .tabs__fallback.is--sticky {
                top: 99px !important;
                margin: 0;
                @include desktop {
                    top: 90px !important;
                }
            }
		}

        .site-container.no-banner {
            main > .content {
                // Not Scrolled without banner
                > section {
                    &:first-child,
                    &:first-of-type {
                        padding-top: 0px !important;
                        @extend .section--ptt;
						
						@include desktop {
							padding-top:150px !important;
						}
                    }
                }
            }
			
			.horizon {
				.booking-section {
					.booking-form-guest-trigger {
						.guest-adult,
						.guest-infant,
						.guest-child {
							&::after {
								background:$t-gold;
							}
						}
					}
				}
			}
        }

        .video-banner__muteBtn{
            @include desktop{
                top:250px;
            }
        }

        .tabs.is--sticky,
        .tabs__fallback.is--sticky {
            top: 177px;
            margin: 0;
            @include desktop {
                top: 150px;
            }
            .tab__item {
                &:hover {
                    @include desktop {
                        border-bottom: 1px solid $t-white;
                    }
                }
            }
            .tab__title {
                > div {
                    @include desktop {
                        color: $t-white;
                
                    }
                    
                }
            }
        }
        .is-scrolled{
            .tabs.is--sticky,
            .tabs__fallback.is--sticky{
                top:147px;
                margin: 0;
                @include desktop {
                    top: 120px;
                }
            }
        }
    }

    &.with-notice.horizon-header {
        main > .content{
            margin-top:$horizon-header-mobile-with-notice-height;
				
			@include desktop {
				margin-top:0;
			}
        }
		
		&.has-event-banner {
			main > .content{
				margin-top:306px;
				
				@include desktop {
					margin-top:0;
				}
			}
			
			.site-container.no-banner {
				main > .content {
					// Not Scrolled without banner
					> section {
						&:first-child,
						&:first-of-type {
							@extend .section--ptt;
							
							@include desktop {
								padding-top:350px !important;
							}
						}
					}
				}
			}
		}
		
		&.hide-booking-mask {
			main > .content{
				margin-top:148px;
				
				@include desktop {
					margin-top:0px;
                    padding-top: 139px;
				}
			}

            .site-container.no-banner {
                main > .content {
                    > section {
                        &:first-child,
                        &:first-of-type {
                            @extend .section--ptt;
            
                            @include desktop {
                                padding-top:unset !important;
                            }
                        }
                    }
                }
            }
		
			&.has-event-banner {
				main > .content{
					margin-top:223px;
					
					@include desktop {
						margin-top:125px;
					}
				}
			}
            .section--ptt {
                padding-top: unset !important; 
            }
            .is-scrolled {
                .tabs.is--sticky,
                .tabs__fallback.is--sticky {
                    top: 118px !important;
                    margin: 0;
                    @include desktop {
                        top: 109px !important;
                    }
                }
            }
            .tabs.is--sticky,
            .tabs__fallback.is--sticky {
                top: 148px !important;
                margin: 0;
                @include desktop {
                    top: 139px !important;
                }
            }         
		}

        .site-container.no-banner {
            main > .content {
                // Not Scrolled without banner
                > section {
                    &:first-child,
                    &:first-of-type {
                        padding-top: 0px !important;
                        @extend .section--ptt;
        
						@include desktop {
							padding-top:200px !important;
						}
                    }
                }
            }
        }
        .is-scrolled{
            .tabs.is--sticky,
            .tabs__fallback.is--sticky{
                top: 169px !important;
            }
        }
        .tabs.is--sticky,
        .tabs__fallback.is--sticky {
            top: 199px;
        }   
    }


    //Typography
    .t-xl{
        text-transform: none;
        font-size:3.6em;
    }
	
    .text-cta{
        padding:0;
        font-size:14px;
        font-weight:bold;
    }

    .section-header {
        .t-xl{
            text-transform: none;
            font-weight:bold;
    
            @include mobile{
                font-size:3em;
            }
        }

        &__text{
            font-weight: bold;
        }

		&--left-aligned {
			.section-header {
				padding-left:0;
				padding-right:0;
			
				* {
					@include text-align(left);
					display: block;
				}
				&__title{
					border-bottom:1px solid $f-grey-light-border;
					padding-bottom:15px;
				}

				&__text{
					max-width:none;
				}
			}
		}
    }

    .btn {
        border-radius: 0;

        &--ghost {
            border-color: $f-gold;

            &:hover,
            &:focus {
                background: $f-gold;
                color: white;
            }
        }

        &--ghost.btn--gold {
            border-color: $f-gold;
            color: $f-gold;
        }

        &--dark {
            color: white;
            background-color: $f-gold;
        }
    }

    .myprivilege {
        .constraint {
            .mybooking {
                color: $t-gold;
                background: white;
				
                .input__field {
                    color: $f-gold !important;
                }
            }

            .myBookingPopupClose {
                color: $t-gold;
            }
        }
    }

    .myprivilege .btn button {
        border: 1px solid transparent;

        &:hover {
            background: transparent;
            border: 1px solid $f-gold;
            color: $f-gold !important;
        }
    }

    .t-c-m p > a.btn--rte-grey,
    .t-c-m p a.btn--rte-gold {
        border: 1px solid $f-gold !important;
    }

    .slinky-menu {
        background-color: $f-gold;

        @include desktop {
            background-color: transparent;
        }

        ul {
            li {
				&.active {
                    a.menu__item__link {
                        border-bottom: 1px solid white;
                    }
				}
				
                a {
                    background-color: $f-gold;
                    border-bottom-color: $f-gold-faint;
                    text-transform: none !important;

                    @include desktop {
                        background-color: transparent;
                        border-bottom: 0;
                    }
                }
            }
        }

        .next {
            $triangle-size: 10px;
            &:after {
                @include border-left($triangle-size solid white);
            }

            &.has-link {
                background-color: $f-gold;
                border-bottom-color: $f-gold-faint;
                @include border-left(1px solid $f-gold-faint);
            }
        }

        .sub-menu__item {
            &.t-gold,
            &.rate__title-link {
                > a {
                    color: white;
                }
            }
        }
    }

    .account-mobile {
        background-color: transparent;
        border-top: 1px solid $f-gold-faint;
        border-bottom: 1px solid $f-gold-faint;
    }

    .menu-mobile {
        background-color: transparent;
        @include border-right(1px solid $f-gold-faint);
        width: 40px;
        @include margin-left(10px);
        @include tablet {
            width: 70px;
        }
    }

    .c-hamburger span {
        background-color: white;

        &:before,
        &:after {
            background-color: white;
        }
    }

    .is-active .c-hamburger span {
        background-color: transparent;

        @include mobile {
            &::before {
                top: -6px;
            }

            &::after {
                bottom: 6px;
            }
        }
    }
    

    .myprivilege .constraint #myProfile .menu {
        background: $f-op50-white;
        box-shadow: $f-minimal-box-shadow;

        a {
            color: $t-gold;

            &:hover {
                background-color: $f-gold;
                color: white;
            }
        }
    }

    .header-primary {
		background-color: $t-gold;
		@include desktop {
            background-color: rgba(19, 33, 60, 0.7);
			backdrop-filter: blur(3px);
		}

		@media screen and (min-width:1025px){
			box-shadow: none;
		}

        .account__booking {
            .btn-internal {
                @include no-desktop {
                    color: $t-gold !important;
                }
            }

            > .btn .close-toggle {
                @include tablet {
                    top: 38px;
                }
                @include mobile {
                    top: 90px;
                }
            }
        }

        .account__booking > .btn {
            background-color: white;
            color: $t-gold !important;
            border: 0;

            &:hover,
            &:focus,
            &:visited {
                color: $t-gold !important;
            }
        }

        .header-inside {
            .is-active {
                .account__booking > .btn {
                    background-color: $f-gold;
                }
            }
        }

        &.is-scrolled {
            background-color: $f-gold;
			
			.account {
				@media screen and (max-width:1024px){
					top:0;
				}
			}

            .header-toolbar {
                @include desktop {
                    transform:translateY(-100px);
                }
            }

            .header-inside {

                .is-active {
                    .account__booking > .btn {
                        background-color: lighten($f-gold, 5%);
                        color: white;

                        @include mobile {
                            background-color: $f-gold;
                            color: white;
                        }


                        .btn-internal,
                        &:hover,
                        &:focus,
                        &:visited {
                            border-color: transparent !important;
                            color: white !important;
                        }
                    }
                }
            }

            .account__booking > .btn {
                background-color: white;
                color: $t-gold;
				
				@media screen and (max-width:1024px){
					height:61px;
				}
				
                .btn-internal,
                &:hover,
                &:focus,
                &:visited {
                    color: $t-gold !important;
                    border-color: white !important;
                }
            }
        }

        &--light-bg {
            .js-destination {
                &:hover{
                    &:after {
                        border-top-color: #55344b;
                    }
                }
            }
        }

        .panel-currency {
            .t-gold{
                color:$t-gold !important;
            }
            .trigger {
               
                @include no-desktop {
                    color: white;
                }
                 
                &:after {
                    border-top-color: white;
    
                    @media screen and (max-width:1024px){
                        border-top-color: white;
                    }
                }
    
                &.active:after {
                    border-bottom-color: white;
    
                    @media screen and (max-width:1024px){
                        border-bottom-color: white;
                    }
                }
            }
    
            .currencyMenu {
                background: white;
                top: 23px;
    
                .currency_list {
                    color: $t-gold;
    
                    li {
                        &.active,
                        &:hover,
                        &:focus {
                            background: $f-gold;
                            color: #fff;
    
                            .currency-item .currency-code {
                                color: #fff;
                            }
                        }
    
                        .currency-item .currency-code {
                            line-height: 1;
                            float: none;
                            color:$t-gold;
                        }
                    }
                }
            }
        }

        //Horizon header
        &.horizon{

            .header-toolbar{
                background: $t-gold;
                justify-content: flex-end;
                transform:translateY(0);
                height:30px;
                padding: 0;
                @include desktop {
                    background:rgba(19, 33, 60, 0.7);
                    padding: 10px 0;
                    height: 30px;
                }
                .container--mw1440  {
                    width: 100%;
                    @include text-align (right);
                    margin: 0 auto;
                    padding-inline: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
    
            .header-inside {
                background: transparent;
                display:flex;
                width:100%;
                transform: none;
                flex-direction: column;
              
                > .wrapper{
                    display:flex;
                    flex-wrap:wrap;
                    align-items: center;
                    justify-content: space-between;
                    width:100%;
                    padding-top:10px;
                    padding-bottom:0px;
                    
                    @include desktop {
                        flex-wrap: nowrap;
                        padding-bottom: 5px;
                    }

                    @include lgDesktop{
                        max-width: 1440px;
                        margin:0 auto;
                        padding: 10px 0 5px;
                    }
                }

            }

            .menu-mobile{
                position: relative;
                border-color:transparent;
            }

            .logo{

                @include desktop{
                    margin:0;
                }

                &__link{
                    height:auto;
                    padding: 0 20px;
                }
            }

            .account-mobile{
                flex: 1 1 auto;
                width:auto;
                height:auto;
            }

            .menu-container{
                background:transparent;
                position: unset;
                @include text-align(right);

                .menu-close.menu-toggle{
                    display:none;
                }

                &.is-active{
                    @include no-desktop{
                        height: 100vh;
                        max-height: none;
                        position: relative;
                    }

                    .slinky-menu{
                        @include no-desktop{
                            height:100% !important;
                        }

                        li.menu__item{
                            @include no-desktop{
                                border-bottom: 1px solid rgba(255, 255, 255, 0.122);
                            }
                        }
                    }
                }

                
            }

            .logo__image {
                max-height:40px;
                position: relative;
                @include left (0);
                @include right (0);
                filter: invert(1) brightness(10);

                @include desktop{
                    max-height:45px;
                }

                @include smallMobile{
                    max-height: 30px;
                }
				
				&.no-filter {
					filter: none;
				}
            }

            .account{
                
                &__link{
                    color:white;

                    &:after{
                        background-color:white;
                    }
                }

                &-mobile{
                    border: 0;
                    transform: none;
                    &__link{
                        font-size:0;
                        padding:0;
                        margin:0;
                        @include margin-right(5px);

                        [class^="glyph-"] {
                            font-size:16px;
                            width:30px;
                            height:30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border:1px solid white;
                            border-radius: 50%;
                        }

                        &:after{
                            display:none;
                        }
                    }
                }
            }

            .personalization-item, .mobile-language-toggle, .panel-currency .trigger, .myprivilege .constraint #myProfile a, .myprivilege .constraint #myProfile{
                color:#fff;
            }

            .language-selector {

                .language-bar {
                    background:white;
                    top: 30px;

                    &__link {
                        color:$f-gold;
                    }
                }

                .mobile-language-toggle{

                    &:after{
                        border-top-color:white;
                    }

                    &.active{
                        &:after{
                            border-bottom-color:white;
                        }
                    }
                }

                &:after{
                    background-color:white;
                }
                
            }

            .myprivilege .constraint .discoveryProfile .discoveryTier:after, .myprivilege .constraint .discoveryProfile .discoveryDollar:after{
               @include desktop{
                    border-top-color:transparent;
               } 
            }

            .myprivilege {
				@include right(10px);
				top:65px;
                top: 50px;

                @include tablet{
                    @include left (unset);
                    @include right (0);
                }

                .constraint #myProfile{
                    @include mobile{
                        top:0;
                        @include right(0);
                        width: calc(100% - 10px);
                        max-width: fit-content;
                    }

                    .discoveryProfile{

                        .discoveryTier {
                            margin-top: 0px;
                            padding-top: 0px;
                            @include margin-right(unset);
                            position: relative;
                            @include padding-right(0px);
							
							@include tablet {
                                @include margin-right(10px);
							}
							
                            @include no-desktop {
                                font-size:0;
                                @include padding-right(16px !important);
                            }


                            img {
                                @include no-desktop{
                                    display:none
                                }
                            }

                            p {
                                @include no-desktop{
                                    font-size:0;
                                }

                                &:before{
                                    @extend %icon-font-glyph;
                                    content: $glyph-profile;
                                    font-size:16px;
                                    position: relative;
                                    @include margin-right(5px);
                                    padding:5px;
                                    border:1px solid white;
                                    border-radius:50%;

                                    @include desktop{
                                        display: none;
                                    }
                                }
                            }
                            
                        }

                        .discoveryDollar{
                            @include no-desktop{
                                display: none;
                            }
                        }
                    }

                    #lnkWelcome{
                        @include no-desktop{
                            font-size:0;
                        }

                        .name{
                            &:before{
                                @extend %icon-font-glyph;
                                content: $glyph-profile;
                                font-size:16px;
                                position: relative;
                                @include margin-right(5px);
                                padding:5px;
                                border:1px solid white;
                                border-radius:50%;

                                @include no-mobile{
                                    display: none;
                                }
                            }
                        }
                    }

                    .menu {
						@include right(-10px);
						transform:none;
						
						@include tablet {
                            @include right(0px);
						}
						
                        @include no-desktop{
                            @include left(auto);
                            top: 42px;
                        }
						
						@include desktop {
							@include right(0);
							top:24px;
						}
                    }
                }

            } 

            //Scrolled state of header
            &.is-scrolled {
                bottom: unset;
                padding-bottom: 0px;
                
                .booking-trigger-desktop {
                    @include desktop {
                        display:none;
                    }
                }

                .header-inside{
                    background:$f-gold;

                    @include no-desktop{
                        padding-bottom:0;
                    }
                }
    
                .header-booking-mask {
                    @include desktop {
                        transform: unset;
                        background:$f-gold;
                    }
    
                    .with-notice & {
                        @include desktop {
                            transform: translateY(0px);
                        }
                    }
					
					.booking-section {
						.booking-form-guest-trigger {
							.guest-adult, .guest-infant, .guest-child
							{
								&::after {
									background:#fff;
								}
							}
						}
					}
                }

                .menu-container{
                    @include desktop {
                       top:0;
                    }
                }
    
                .header-toolbar{
                    bottom:0;
    
                    @include desktop{
                        transform:translateY(-100px);
                    }
                }
    
            //     .logo {
            //         &__image {
            //             display: block;
    
            //             &--alternate {
            //                 display: none;
            //             }
            //         }
            //     }
    
            //     .account-mobile {
            //         height: 62px;
            //         transform: translateY(-200px);
            //     }
    
                .menu-mobile {
                    opacity: 1;
                }
    
                .account.member {
                    @include no-desktop {
                        transform: none;
                        opacity: 1;
                        top:auto;
                    }
                }
            }

            .header-booking-mask{
                background: transparent;

                a.booking-trigger.no-desktop.btn{
                    display:none;
                }

                &.is-active{
                    a.booking-trigger.no-desktop.btn{
                        display:block;
                    }
                    a.booking-trigger {
                        border: unset;
                    }
                    a.booking-trigger:hover {
                        background: $f-gold;
                    }

                    .nano.is-booking-mask {
                        .nano-content {
                            @include tablet{
                                top: 45px;
                            }
                        }
                    }
                }

                > .container{
                    @include desktop {
                        padding: 10px;
                        padding-top: 5px;
                    }
                }
            }

            .booking-section{
                background:white;
                

                @include lgDesktop {
                    padding-inline: 10px;
                }
                @include desktop{
                    background: transparent;
                    padding: 0px;
                }

                .date__group{

                    @include no-desktop{
                        border-radius:0;
                        background:#f1eff0;
                    }

                    &:after{
                        @include no-desktop{
                            border-top-color:$t-standard;
                        }
                    }

                }
                

                .date__number, .date__month, .date__day{
                    @include no-desktop{
                        color: $t-standard;
                    }
                }

                .date__number{
                    &.t-xl{

                        @include mobile{
                            font-size:3em;
                        }
                    }
                }

				.select2-container--anantara {
					.select2-selection--single {
						.select2-selection__rendered, .select2-selection__placeholder {
							@include no-desktop{
								color: $t-standard;
								background: #f1eff0;
							}
						}
						
						.select2-selection__arrow {
							b {
								@include no-desktop{
									border-color: $f-gold transparent transparent transparent;
								}
							}
						}
					}
				}
				
                .booking-destination #destination-hotel{
                    @include no-desktop{
                        color: $t-standard;
                        background: #faf3f3;
                    }
                }

                .stepper-input{
                    @include no-desktop{
                        color:$t-standard;
                    }
                }

                .booking-promo .booking-form-subgroup{
                    height: 45px;
                    margin-top: 0px;
                    @include no-desktop{
                        color: $t-standard;
                        background: #f1eff0;
                    }

                    .promo-btn{
                        @include no-desktop{
                            color: $t-standard;
                        }
                    }
                }
              
                .booking-form{

                    &-label{
                        color:$t-standard;

                        @include desktop{
                            color:white;
                        }
                    }

                    .booking-guest{
                        .booking-form-subgroup{
                            background:#f1eff0;
                        }
                        .booking-form-subgroup-label{
                            color:$t-standard;
                        }
                    }

                    .booking-form-guest-trigger {
						.guest-adult,
						.guest-infant,
						.guest-child {
							&::after {
								background:white;
							}
						}
					}

                    .button__submit{
                        @include desktop{
                            background:transparent;
                            border: 1px solid #fff;
                        }

                        &:hover{
                            @include desktop{
                                background:white;
                                color:$f-gold;
                            }
                        }
                    }
                }

                .booking-form-group.booking-room{
                    @include no-desktop{
                        background: $f-gold !important;
                        color:#fff;
                    }

                    .js-clear-booking{
                        .c-hamburger span{

                            &:before, &:after {
                                color:#fff;
                                background-color: #fff;
                                top:0;
                            }

                        }
                    }
                }
            }

            .panel-currency{
                .currency-converter{

                    .form{
                        .form-main{
                            &:after{
                                border-top-color:white;
                            }
                        }
                    }
                    select{
                        color:#fff;
                    }
                }
            }

            .slinky-menu {
                justify-content: flex-end;
                @include text-align (right);
            }

            .destinations{
                @include left(0);
                top:calc(#{$horizon-header-toolbar-height} + #{$horizon-booking-mask-height});
            }

            .js-clear-booking{
                .c-hamburger{
                    span{
                        background:transparent;

                        &:after, &:before{
                            color:$f-gold;
                            background-color: $f-gold ;
                        }
                    }
                }
            }

            .menu-dimmer{
                z-index: -1;
				opacity:0;
            }

            .calendar-container .calendar-header{
                @include mobile{
                    width:100%;
                }
            }
            
            //Mobile booking trigger
            .quick-search-section{
                display: flex;
                align-items: center;
                padding: 5px;
                border-top: 1px solid transparent;
                width:100%;
                padding-top: 0px;
                @include desktop {
                    padding-top: unset;
                }
                .search-field{
                    flex: 1 1 auto;
                }

                .booking-form-group {
                    padding: 5px;
                    @include text-align (left);
                }

                input, select {
                    width: 100%;
                    height: 40px;
                    background: rgba(255, 255, 255, 0.28);
                    border: 0;
                    color: white;
                    padding: 5px;
                    font-size:13px;
                    @include padding-right (30px);


                    &::placeholder{
                        color:white;

                    }
                }

                .input-wrapper{
                    position: relative;
                    i{
                        position: absolute;
                        @include right (15px);
                        top: 55%;
                        color: white;
                        font-size: 18px;

                    }
                }

                .booking-form-label{
                    font-size: 13px;
                    color: white;
                    text-align: left;

                }
            }

        }
        

        .select2 {
            &-container {
                &--anantara {
                    .select2 {
                        &-search {
                            &--dropdown {
                                &:after {
                                    color: $f-gold;
                                }
                            }
                        }
                    }
                }
            }
        }

        .slinky-menu {
            ul {
                > li {
                    > a {
                        &.active, &:hover, &:focus {
                            color: $f-grey-light;

                            @include desktop {
                                .header-primary--light-bg & {
                                    color: $f-gold-overlay;
                                }
                            }
                        }
                    }
                }
            }

            .brand-home {
                > a {
                    color: white;

                    &:before {
                        @include border-right(8px solid white);
                    }
                }
            }
        }

        .js-destination:after{
            border-top-color:white;
        }
    }

    // Style header for MHG when no banner

    .site-container.no-banner{

        .header-primary.horizon{

            .language-selector .language-bar{
                background-color: $f-gold;

                h4 {
                    color:#fff !important;
                }

                a {
                    color: #fff;
                }
				
				h4, div.t-underline.t-gold {
					color:#fff !important;
				}
            }

            .panel-currency .currencyMenu {
                background-color: $f-gold;
                h4, div.t-underline {
                    color:#fff !important;
                }
                .currency_list{
                    li{
                        .currency-item{
                            color:#fff;

                            span{
                                color:#fff;
                            }
                        }

                        &:hover{
                            background: rgba(255, 255, 255, 0.141);
                        }
                    }
                }
            }
        }
        .header-primary.horizon:not(.is-scrolled){

            .logo__image{
                filter:none;
            }

            .header-toolbar{
                background:$f-gold;
            }

            .header-inside{
                background:$section-white-bg-color;

                .slinky-menu {
                    ul {
                        > li {
                            &.menu__item{
                                @include desktop{
                                    color:$t-standard;
                                }
                            }
                            > a {
                                &.active, &:hover, &:focus {
                                    color: $f-gold-faint;
                                }
                            }
                        }
                    }
                }

            }

            .header-booking-mask{
                background: $section-white-bg-color;

                .booking-section{

                    .select2-container--anantara .select2-selection--single, .booking-destination #destination-hotel{
                        color:$t-standard;
                        background: $f-grey-faint;
    
                        .select2-selection__rendered, .select2-selection__placeholder {
                            color:$t-standard;
                        }
    
                        .select2-selection__arrow b{
                            border-color:$f-gold transparent transparent transparent;
                        }
                    }
    
                    .booking-date__wrapper{
                        background: $f-grey-faint;
                        color:$t-standard;
                    }
    
                    .booking-form-guest-trigger .booking-form-guest-wrapper{
                        background: $f-grey-faint;
                        color:$t-standard;
                    }
    
                    .promo-btn{
                        color:$t-standard;
                    }

                    .booking-form-label{
                        @include desktop{
                            color:$t-standard;
                        }
                    }

                    .date__group, .booking-form-guest-wrapper{
                        &::after{
                            border-top-color:$f-gold;
                        }
                    }

                    .date__number, .date__month, .date__day{
                        @include desktop{
                            color:$t-standard;
                        }
                    }

                    .booking-form .button__submit{

                        @include desktop{
                            background:$f-gold;
                            border: 1px solid $f-gold;
                        }

                        &:hover{
                            @include desktop{
                                background:transparent;
                                border: 1px solid $f-gold;
                                color:$f-gold;
                            }
                        }
                    }
                }
            } 

            .menu-mobile{
                .c-hamburger{
                    span:before, span:after, span{
                        background-color:$f-gold;
                    }
                } 

                &.is-active{
                    .c-hamburger{
                        span{
                            background: transparent;
                        };
                    }
                }
            }

            .account{

                &-mobile{

                    &__link{

                        [class^="glyph-"] {
                            border-color:$f-gold;
                            color: $f-gold;
                        }

                    }
                }
            }

            .myprivilege{

                .constraint #myProfile{

                    @include no-desktop{
                        color:$t-standard;
                    }

                    .discoveryProfile{

                        .discoveryTier{

                            p{
                                &:before{
                                    border-color:$f-gold;
                                    color:$f-gold;
                                }
                            }

                            &:after{
                                border-top-color:$f-gold;
                            }
                            
                        }
                    }

                    #lnkWelcome{

                        .name{
                            &:before{
                                border-color:$f-gold;
                                color:$f-gold;
                            }
                        }
                    }

                    .menu{
                        .name{
                            color:#fff;
                        }
                    }
                }

            } 

            .quick-search-section{
                background:white;
                border-top-color: 1px solid rgba(0, 0, 0, 0.149);


                input, select {
                    background: $f-grey-faint;
                    color: $f-gold;


                    &::placeholder{
                        color:$f-gold;

                    }
                }

                .input-wrapper{

                    i{
                        color: $f-gold;

                    }
                }

                .booking-form-label{
                    color: $f-gold;
                }
            }

            
        }
    }

    // Section
    .section {
        &-header {
            &__title {
                color: $t-standard;
            }
        }
		
		/*
        &-footer:not(#discovery-dollars *) {
            &__text {
                color: $t-gold;
            }

            .btn {
                background-color: transparent;
                border:1px solid white;
                color: white;

                &:hover {
                    background-color: white;
                    color: $f-gold !important;
                }
            }
        }
		*/
		
        &--blog-intro{
            background-color:$f-grey-light;
            color:$t-standard;

            + .section--same-as-previous {
                background-color:$f-grey-light;
                color: $t-standard;
            }

            .section-header__title, .section-header__text{
                color:$t-standard;
            }
        }
		
		&--light-grey {
			background-color:#FFF;
		}

        &--dark-grey , &--gold, &--darker-grey{
            background-color: $f-grey-dark;
			color:$t-white;

            & + .section--same-as-previous {
                background-color: $f-grey-dark;
                color: $t-white;
            }

            .section-header__title, .section-header__text{
                color:#fff;
            }

            .static-list__content{
                color:$t-standard;
            }

            .carousel-tabs__nav .carousel-tabs__cat{
                color:white;

                &:hover, &:active{
                    color:$f-grey-light;
                }

                &.is-active{
                    color:white;
                    border-bottom:1px solid white;
                }
            }

            .carousel-tabs{
                .gallery, .gallery-slide {
                    & ~ .carousel-tabs__description, .gallery-slide__text {
                            background-color: $f-grey-light;
                    }
                }
            }

            .rate__item, .current-search__container, .meeting-room{
                color:$t-standard;
            }
			
			.t-grey {
				color:$t-white!important;
			}
			
			.btn {
				&--gold {
					background-color:$t-white;
					color:$t-gold!important;
				}
			}
        }

        .section--same-as-previous{
            background:$f-grey-light;
            color:$t-standard;
        }

        &-category {
            &__form {
                .section__heading {
                    color: $f-gold;
                }
            }
        }

        &--blue-blue-tone {
            background: linear-gradient(90deg, $f-pale-blue-tone 0%, $f-pale-blue-tone 50%, #dadbde 50.001%, #dadbde 100%);

            @include mobile {
                background: $f-pale-blue-tone;
            }

            .section-header__title, .section-header__text{
                color:$t-standard;
            }
        }

        &--lighter-grey{
            background: $f-grey-light;
        }
    }

    .section--dark-grey,
    .section--light-grey {
        .slick {
            &-dots {
                li {
                    button {
                        &:before {
                            border-color: $f-grey-light;
                        }
                    }

                    &.slick-active button:before {
                        background-color: $f-grey-light;
                    }
                }
            }
        }
    }


    .section--white{
        .destinations {
            a {
                color: $t-gold;
            }

            &__menu {
                > ul > li {
                    > a,
                    .fallback-title {
                        color: #000;

                        &:hover,
                        &:focus {
                            color: $t-standard-light;
                        }
                    }
                }
            }
        }
    }


    .section--halfheight {
        @include desktop {
            height:80vh;
		
			.full-bg {
				height:80vh;
			}
		}
		
		&.section--center-content {
			@include desktop {
				.full-bg {
					height:80vh;
				}
			}
		}
    }

    .section--center-content {
        .full-bg, .full-bg.parallax-window {
			@include desktop {
				height:65vh;
			}
        }
    }


    // Destination
    .destinations {
        background: $f-pale-blue-tone;

        a {
            color: $t-gold;
        }

        &__menu {
            &.has-cities > ul > li,
            > ul > li {
                .fallback-title {
                    color: #000;

                    &:hover,
                    &:focus {
                        color: $f-gold-faint;
                    }
                }

                // City Level
                > a {
                    &:hover, &:focus {
						color: $t-standard-faint;
					}

                    &.view-all {
                        &:hover, &:focus {
                            background: $f-op75-white;
                        }
                    }
                }

                // Resort level
				> ul > li {
                    > a {
                        &:hover, &:focus {
                            color: $t-standard-faint;
                        }
                    }

                    // Mini-Resort level
						> ul > li {
                            > a {
                                &:hover, &:focus {
                                    color: $t-standard-faint;
                                }
                            }
                        }
                }

                > a.view-all {
                    // background-color: black;
                    // color: white;
                }
            }
        }

        .js-destinations__close {
            background: black;
        }
    }
    .header-primary.is-scrolled .destinations {
        background: $f-pale-blue-tone;
    }

    // Destination Explorer Overlay
    .destination-explorer {
        background-color: $f-pale-blue-tone;

        .full-bg {
            img {
                display: none;
            }

            &.full-bg--has-overlay {
                color: $t-gold;

                &:before {
                    background-color: rgba(0, 0, 0, 0);
                }

                .title {
                    color: $t-gold !important;
                }

                .destinations__menu {
                    color: $t-gold;
                }
            }

            &__overlay {
                background: linear-gradient(to bottom,$f-pale-blue-tone 50%,$f-pale-blue-tone 100%);
            }
        }
    }

    // Play button
    .intro-video__play {
        background-color: rgba(255, 255, 255, 0.6);

        &::before {
            border-color: transparent transparent transparent rgba(19, 33, 60, 0.8);
        }
    }

    // Fancybox
    .fancybox-close .close-btn {
        background-color: $f-gold;
    }

    // Testimonial
    .testimonial {
        .facility-rating__heading > span{
            font-size:6em;
            font-weight:bold;
        }

         .section-header{
            padding:0;
        }
    }

    // Awards
    .awards {
        .t-underline:after {
            background-color: $f-gold;
        }
    }

    // Full breed
    .news-container--feature,
    .news-container {
        .full-bg {
            .section-header{
                &__title {
                    color: white;
                }
            }
        }
    }

    .full-bg{
        &__overlay {
            background: linear-gradient(to bottom, transparent 50%, rgba(0,0,0, 0.6) 100%);
            z-index: 0;
        }

        .section-header__title {
            color: white;
        }

        .testimonial {
            .section-header{
                &__title {
                    color: $t-gold;
                }
            }
        }

        .awards {
            .section-header{
                &__title {
                    color: $t-gold;
                    font-weight: 600;
                }
            }
        }

        .section{
            &-footer__text {
                color: white;
            }
        }

        .contact-inline {
            color: white;

            .t-gold,
            strong {
                color: white !important;
            }

            .t-underline:after {
                background-color: white;
            }

            .btn--gold.btn--ghost {
                border: 1px solid white;
                color: white !important;

                &:hover {
                    background-color: white;
                    color: $t-gold !important;
                }
            }
        }

        .slick {
            &-dots {
                li {
                    button {
                        &:before {
                            border-color: white !important;
                        }
                    }

                    &.slick-active button:before {
                        background-color: white!important;
                    }
                }
            }
        }
    }

    // Experience
    .video-cta {
        &__heading {
            color: white !important;
        }

        &--full-height {
            .video-cta {
                &__heading {
                    font-size: 1.8em;
                }

                &__title {
                    font-size: 2.5em;
                }
            }
        }

        &__title{
            color: white !important;
        }

        &__link:before {
            border: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255,255, 0.3);
            z-index: 0;
        }
    }

    // Triangle
    .triangle{
        &-cta,
        &-cta--odd {
            &:before {
                border: 0;
                width: 100%;
                background: rgba(255, 255, 255, 0.6);

                @include mobile {
                    right: 0;
                    left: 0;
                    @include margin-left(0);
                }
            }

            // &__text {
            //     @include mobile {
            //         bottom: 50%;
            //         transform: translateY(50%);
            //     }
            // }
        }
    }

    // Text reveal cta
    .text-reveal{
        .text-reveal__wrapper{
            .text-cta{
                color:#fff;
                margin: 5px 0;
                
                &:before {
                    display: none;
                }
            }

        }

        &__title {
            font-weight: 500;
        }

        .currency-converter select {
            border-bottom-color: white;
        }

        .btn--ghost {
            border-color: #fff;

            &:hover{
                border-color: #461035;
                background-color: #461035;
            }
        }

        &__bookingBenefits {
            color: #fff;

            &Title {
                color: #fff;
            }
        }

        // You may also like
        .t-underline:after {
            background-color: white;
        }

        &.is-card__display {
            .text-cta{
                font-weight:normal;
                padding: 5px 0;
                color:$f-gold;
                margin:5px 0;
                
                &:before {
                    display: none;
                }
            }
        }
    }

    .section--dark-grey, .section--darker-grey, .section--gold {

        .other-offers .slick-slide, .offers .slick-slide{
            border-color:$f-grey-dark;
        }
        
    }

    .section--lighter-grey {

        .other-offers .slick-slide, .offers .slick-slide{
            border-color:$f-grey-light;
        }
        
    }

    .compare-list {
        &__heading {
            font-weight: bold;
            color: white !important;
        }
    }

    .dynamic-list, .static-list {
        &__item {
            box-shadow:none;
            border: 1px solid $f-grey-light-border;

            &.is-card__display {
                .t-underline:after {
                    background-color:  $f-gold;
                }

                .text-reveal{
                    &__bookingBenefits {
                        color: $t-gold;

                        &Title {
                            color: $t-gold;
                        }
                    }
                }

                .btn--ghost {
                    border-color: $f-gold;

                    &:hover,
                    &:focus {
                        background-color: $f-gold;
                    }
                }

                .currency-converter select {
                    border-bottom-color: $f-gold;
                }

                .text-cta--more-details{
                    font-weight:normal;
                    border-bottom:1px solid $f-grey-light-border;
                    display: inline-flex;
                    width: auto;
                    padding: 5px 0;
                    color:$f-gold;
                    
                    &:before {
                        display: none;
                    }
    
                    &:hover{
                        border-bottom-color:$f-gold;
                    }
                }

            }

            .text-cta--more-details{
                font-weight:normal;
                border-bottom:1px solid $f-grey-light-border;
                display: inline-flex;
                width: auto;
                padding: 5px 0;
                
                &:before {
                    display: none;
                }

                &:hover{
                    border-bottom-color:$f-gold;
                }
            }
        }

        &__title{
            margin-bottom:20px;
        }

        &__text-container{
            padding:15px;
        }
    }

    .text-reveal.is-card__display {
        .t-underline:after {
            background-color:  $f-gold;
        }

        .text-reveal{
            &__bookingBenefits {
                color: $t-gold;

                &Title {
                    color: $t-gold;
                }
            }
        }

        .btn--ghost {
            border-color: $f-gold;

            &:hover,
            &:focus {
                background-color: $f-gold;
            }
        }

        .currency-converter select {
            border-bottom-color: $f-gold;
        }
    }

    .content-intro {
        .features-special {
            border-top-color: $f-header-bg-light;
        }
    }
	
	.content-intro-complex__text {
		.text-reveal{
            &__bookingBenefits {
                color: $t-gold;

                &Title {
                    color: $t-gold;
                }
            }
        }
	}

    .privilege-discount {
        border-top-color: $f-header-bg-light;
        border-bottom-color: $f-header-bg-light;
    }

    .other-offers {
        @include mobile {

            .text-reveal {
                &__title.t-underline {
                    &:after {
                        background-color: $f-gold;
                    }
                }

                &__text {
                    color: $t-gold;
                }

                .text-reveal__wrapper {
                    .text-cta {
                        color: $t-gold;

                        &--more-details {
                            &:before {
                                @include border-left(29px solid $f-gold);
                            }
                        }
                    }


                }

                .currency-converter {
                    .form {
                        .form-main,
                        .trigger {
                            &:after {
                                border-top-color: $f-gold;
                            }
                        }
                    }

                    select {
                        border-bottom-color: $f-gold;
                        color: $t-gold;
                    }
                }
            }
        }
    }

    .offers--only-one .text-reveal {
        background: $f-gold;
    }

    // Social Media
    .olapic #olapic-slider-widget .olapic-carousel .olapic-cta-item {
        background: $f-gold;
    }

    // Footer
    footer{
        background: $f-gold;

        .footer {
            &-middle {
                background: $f-gold;
                border-top-color: transparent;
                padding:30px 0;
            }

            &-hotels {
                background: $f-gold;
                border-top-color: transparent;
                .footer-brand__item {
                    background: $f-gold !important;
                }
            }

            &-bottom {
                background: $f-gold;
                border-top-color: $f-op75-white;
            }

            &-detail-links a {
                color: white;
            }
        }

        .newsletter{
            border:1px solid $f-op75-white;

            .btn-submit{

                &:before{
                    @include border-left(15px solid white);
                }

            }

        }

        .footer-brand-newsletter{
            background:#98D0EF;
            padding:4em 0;
            text-align: center;

            .newsletter{
                margin:0 auto;
                max-width:100%;
                color:$t-standard;
                border: 0;
                @include tablet {
                    max-width: 85%;
                }
                .input-text{
					@extend %font-header;
					
                    border:0;
                    border-bottom:2px solid $t-standard;
                    color:$t-standard;
                    font-size:2.5em;
                    height:70px;
                    width:90%;
                    font-weight:bold;
                    padding: 0;

                    @include tablet {
                        height: 100px;
                    }
                    @include desktop{
                        font-size:4em;
                    }

                    &::placeholder{
                        color:$t-standard;
                    }
                }

                .btn-submit {
                    height:70px;
                    width:10%;
                    border:0;
                    border-bottom:2px solid $t-standard;
                    justify-content: flex-end;
                    @include tablet {
                        height: 100px;
                    }
                    &:before{
                        @include border-left(15px solid $f-gold);
                    }
                }
            }

            
        }
    }

    .copyright {
        color: white;
    }

    .engagement{
        &__title.t-white {
            color: $t-white !important;
        }

        &__link:hover{
            border:0;
            background:transparent
        }
    }

    // Dynamic-list
    .dynamic-list__pagination,
    .static-list__pagination {
        & > li {
            &.active {
                background: $f-gold;

                a {
                    color: white;
                }

                &:hover,
                &:focus {
                    background: $f-gold-overlay;
                }
            }


            &:hover,
            &:focus {
                background: $f-grey-dark;
            }
        }
    }

    // Meeting
    .tablesaw-cell-content {
        .t-s {
            font-weight: 300;
            letter-spacing: 1px;
        }
    }

    // Carousel Tab
    .carousel-tabs {
        &__item {
            &.is-active {
                .carousel-tabs__cat {
                    background: transparent;
                    color: $t-gold;

                    @include mobile{
                        background:$f-grey-light;
                    }

                }

                .carousel-tabs__content{
                    @include mobile{
                        background:$f-grey-light;
                    }
                }
            }

            .carousel-tabs__cat {
                background: transparent;
                color: $t-gold;
                margin-bottom:10px;
            }
        }

        &__nav {

            .slick-list{
                margin-bottom:20px;
                height: auto;
                border-bottom:1px solid $f-grey-light-border;
            }
            .carousel-tabs__cat {
                color:$t-gold;
                &:before {
                    background:transparent;
                }

                &.is-active {
                    color:$t-gold;
                    border-bottom:2px solid;
                    &:hover,
                    &:focus {
                        color:$t-gold;

                        &:before {
                            background: transparent;
                            display: none;
                        }
                    }

                    &:before {
                        background: transparent;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:hover,
                &:focus {
                    color:$t-gold;
                    &:before {
                        background: transparent;
                    }
                }
            }
        }

        &__content {
            background: transparent;

            .section--dark-grey & , .section--darker-grey & , .section--gold & {
                background: $f-grey-light;
            }

            @include mobile {
                color: $t-standard;
                background: transparent;

                .section--dark-grey & , .section--darker-grey & , .section--gold & {
                    background: $f-grey-light;
                }
            }
        }

        .features-special {
            [class^='glyph'] {
                @include mobile {
                    color: $f-gold;
                }
            }
        }

        .gallery,
        .gallery-slide {
            & ~ .carousel-tabs__description,
            .gallery-slide__text {
                background-color: transparent;
                box-shadow: none;
            }

            .slick-next {
				@include right(-30px);
				
                @include desktop{
					@include right(-35px);
                }
                
                &:before {
                    @include border-left(10px solid $f-gold);
                }
            }

            .slick-prev {
				@include left(-30px);
				
                @include desktop{
					@include left(-35px);
                }

                &:before {
                    @include border-right(10px solid $f-gold);
                }
            }

            .slick {
                &-dots {
                    li {
                        button {
                            &:before {
                                @include no-desktop {
                                    border-color: $f-gold;
                                }
                            }
                        }

                        &.slick-active button:before {
                            @include no-desktop {
                                background-color: $f-gold;
                            }
                        }
                    }
                }

                &-arrow{
                    width:30px;
                    height:30px;
                    mix-blend-mode: normal;
                }
            }
        }
    }

    // Gallery-slide
    .gallery-slide {
        .btn--ghost {
            color: $t-gold;
        }

        &.hero-banner-slide{
            top:0;
            transform: translateY(0)!important;

            .gallery-slide__text{
                background:transparent;
                top:60%;

            }

            .gallery-slide__title{
                font-size:3em;
                margin: 0 0 20px 0;
                font-weight:bold;

                @include desktop{
                    font-size:4em;
                }
            }

            .carousel-tabs__ctas{

                .btn{
                    background:white;
                    color:$f-gold !important;
                    border:1px solid white;

                    &:hover{
                        background:transparent;
                        color:white !important;
                    }
                }
            }

        }

        &__description{
            font-size:1em;
        }
    }

    //Carousel arrow
    button.slick-arrow:not(.gallery-modal--fullscreen button.slick-arrow) {
        width:25px;
        height:25px;
        border-radius: 50%;
        background:white;
        display: block;
        text-align: center;
        box-shadow:3px 2px 6px rgba(0, 0, 0, 0.133);

        &:before{
            opacity: 1;
            width:8px;
            position: relative;
			display:inline-block;
        }
		
		&.slick-next {
            @include right(5px);

            &:before{
                border-top: 8px solid transparent;
                @include border-left(8px solid $f-gold);
                border-bottom: 8px solid transparent;
                @include left (2px);
            }
		}
		
		&.slick-prev {
            @include left(5px);
            &:before{
                border-top: 8px solid transparent;
                @include border-right(8px solid $f-gold);
                border-bottom: 8px solid transparent;
                @include right (2px);
            }
		}
	}

    // rte-block
    .rte-block{
        &__heading {
            &.t-underline:after{
                background: $f-grey-light-border;
				
				@include desktop {
					width:90%;
				}
            }
        }
    }

    // Static Listing
    .static-list {
        &__image {
            .t-underline:after {
                background-color: white;
            }
        }
    }

    // Litepicker
    .litepicker {
        .container__days {
            .day-item {
                &.is-in-range {
                    background-color: $f-grey-light-border;
                }
            }
        }
    }

    // Meeting Room
    .meeting-room {
        background-color: white;
    }

    // contact-inline
    .contact-inline {
        .contact-inline__list {
            &.is-dark {
                a {
                    color: $t-standard !important;
                }
            }
        }
    }

    .localtime,
    .weather {
        &-label,
        &-value {
            font-weight: 600;

            &-celcius,
            &-fahrenheit {
                font-weight: 600;
            }
        }
    }

    // rte block
    .rte-block {
        &__heading {
            font-weight: 600;
        }
    }

    // Travel advisory
    .notification {
        border-color: $-form-border;

        &-header {
            font-weight: 600;
        }
    }

    // accordian
    .accordion {
        &__item {
            border-bottom:1px solid $f-grey-light-border;
        }
		
        &__title {
            background:transparent;
            .t-s {
                font-weight: 600;
            }
			
			&:hover {
				background:transparent;
				border-bottom:1px solid $f-gold;
			}
			
			h2 {
			}
        }

        &__content {
            background: transparent;
			padding:10px 40px;
        }
    }

    // Policy Item
    .policy-item  {
        .t-xs {
            font-weight: 600;
            letter-spacing: 0;
        }
    }

    // Info popup
    .inline-popup table tr th {
        color: $f-gold;
        font-weight: 600;
    }

    .rate {
        .price_label.member {
            background: $f-gold;
        }

        .price_container {
            .option {
                border-color: $f-grey-mid;
            }
        }
        
        .discovery-dollar-banner {
            border-color: $f-grey-mid;
        }

        .features-special {
            .feature__item {
                &.non-refundable {
                    color: $t-standard-faint;
                }
            }
        }
    }

    .current-search {
        &__summary {
            .summary-item {
                .item_title {
                    color: $t-standard-faint;
                    
                    .glyph {
                        color: $t-standard-faint;
                    }
                }
            }
        }
    }

    // table
    table thead th {
        color: white;
    }

    // Social Share
    .social-share {
        &__title {
            font-weight: 600;
        }
    }

    // Vimeo
    .section-mobile-feature-video {
        .vimeo__title {
            text-align: center;
            max-width: 90%;
        }
    }

    // Location
    .location-map {
        &-listing {
            // background: $f-gold;
            // color: white;
            background: white;
            color: $t-gold;

            .coordinates {
                &-group a,
                .label {
                    // color: white !important;
                }
            }
        }

        &-list {
            > li {
                border-bottom-color: $t-standard-on-gold;
            }
        }

        ul {
            li {
                .col-2 {
                    color: $t-standard-faint;
                }
            }
        }

        .main-content {
            border-bottom-color: $t-standard-on-gold;
        }

        .information-content {
            .information__header {
                background: $t-standard;
            }
        }
    }

	form:not(.section-category__form, #booking-mask-form, #booking-mask-form--fmb) {
		select {
			optgroup {
				color: $f-grey-mid;
			}

			option {
				color: $t-standard;
				font-size: 1em;
			}
		}

		&.RFP {
			select {
				optgroup {
					color: $f-grey-mid;
				}

				option {
					color: $t-standard;
					font-size: 1em;
				}
			}
		}
	}

    // Dynamic map
    .dynamic-map {
        &.is-destination {
            .map-custom-control {
                .map-custom-button{
                    &:hover {
                        background: $f-gold;
                    }
                }
            }
        }
    }

    // Text-listing
    .text-listing {
        &__item {
            border-bottom-color: $f-grey-border;
        }

        &__meta {
            color: black;
        }
    }

	.inline-popup {
		.js-close {
			color:$f-gold;
		}
	}

    // Tripadvisor
    .trip-advisor__link {
        &:hover {
            text-decoration: none !important;
        }
    }
	
	.countdown {		
		&--banner {
			.time {
				box-shadow: 1px 1px $f-grey-mid;
			}
		}
	}
	
	//Direct booking benefit

    //Content carousel
    .content-carousel {

        .carousel-slide {
            
            &-thumb {
                min-height: 300px;
                
                @include tablet {
                    height: 500px;
                }
                
                @include lgDesktop {
                    width: 50%;
                    min-height: 500px;
                }
            }
    
            &-content {
                
                @include lgDesktop {
                    width: 50%;
                    padding:60px;
                }
            }
    
    
            &-title {
               font-size:4em;
               font-weight:bold;

               @include mobile{
                   font-size:2em;
               }
            }
    
            &-text {
                margin-bottom: 20px;
            }
    
            // &-cta {
            //     .btn {
            //         padding: 10px 20px;
            //         text-transform: uppercase;
    
            //         [lang="en"] & {
            //             letter-spacing: 2px;
            //         }
            //     }
    
            // }
        }
        .slick-next{
            @include right (20px);
        }

        .slick-prev{
            @include left (20px);
        }
    }

    //Image highlight Block

    .image-highlight-block{
        &.brand-logos {
            @include desktop {
                flex-wrap: nowrap;
            }
			
            .slick-next {
                @include right (40px  !important);
                top:45%;
            }
    
            .slick-prev {
                @include left (40px !important);
                top:45%;
            }

            .slick-track {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .image-highlight__item {
                    margin-bottom: 0;
            
                    @include mobile{
                        max-width:100%;
                    }
            
                    img{
                        max-width: 100%;
                        height: 80px;
                        @include tablet {
                            height: 120px;
                        }
                        @include desktop {
                            max-width: 80%;
                            height: unset;
                        }
                    }
                }    
            }    
        }

        &.destination-highlights{

            .image-highlight__link{
                align-items: flex-start;
            }

            .image-highlight__text {
                border-top:1px solid $f-grey-light-border;
                padding-top:10px;
                margin-top:20px;

                .image-highlight__title{
                    font-size:0.9em;
                    letter-spacing: 1px;
                    font-weight: bold;
                }

            }
        }
    }

	//Gallery Modal
	.gallery-modal--fullscreen {
	}
	
    //Destination tabs
    .tabs {
        &.brands-filter {
            padding: 15px 5px;
			@include text-align(left);
			padding:0;
			border-bottom:1px solid $f-grey-light-border;

			.tab__item{
				margin:8px;
				min-width:110px;
                margin: 0.3em 1.2em 0.2em;

				@include desktop{
					@include margin (0 ,30px, 0 , 0);
				}
			}
			
			.tab__title{
				&.active{
					> div{
						&:after{
							@include desktop{
								height: 2px;
								width: 100%;
								background: $f-gold;
								border:0;
								bottom: -2px;
							}
						}
					}
				}
			}
		}
    }

    //Anchor Links

    .anchor-links-container {
        padding:0;

        &:not(.filled *) {
            border-bottom:1px solid $f-grey-light-border;
            @include desktop {
                justify-content: flex-start;
            }
        }

        .anchor-item{
            border:0;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            border-radius: 0;
            margin-bottom: 0;
            font-family: $font-raleway;

            &:hover, &:focus, &:active{
                border-bottom:2px solid $f-gold;
                transition: all 0.3s ease-in-out;
                background:transparent;

                .t-c-m &{
                    border-bottom:2px solid $f-gold;
                }
            }
            &.active {
                &::after {
                    bottom: -1px;
                }
            }
        }
    }

    .destination-grid.is-scrollable{
        .destination-item{
            .destination-title{
                bottom:0;
                @include left(0);
                background:white;
                font-size:1em;
                color:$t-standard;
                font-weight:bold;
                text-transform: none;
                padding:15px 5px;
                width: 100%;
            }
        }
    }

    .engagement .connected, .engagement .cro{

        .rte-block__heading{
            color:#fff;
        }
    }
    
    //Listing layout

    .listing-item{
        &.brand-listing-item{
            background:$f-grey-faint;

            .listing-item--video .listing-item__icon{
                background:rgba(0, 0, 0, 0.771);
            }
        }
    }

    // Hotel directory
    .hotel-directory {
        .hotel__info__rating{
            color: lighten($f-gold, 8%);
        }

    }
	
	.location-content {
		.location-heading {
			border-bottom:1px solid $f-grey-light-border;
			padding-bottom:10px;
			margin-bottom:10px;
		}
	}
	
	.content-block {
		padding:0 10px 35px;
		
		&__text {
			.nano-content {
				@include desktop {
					padding-top:0;
				}
			}
		}
		
		&__media {
			.map {
				display:none;
				
				@include tablet {
					display:block;
					height: calc(80vh - 134px);
				}
				
				@include desktop {
					display:block;
				}
			}
		}
	}
	
	.checkin-widgets {
		display:block;
	    padding: 10px 0;
		border-top:1px solid $f-grey-light-border;
		border-bottom:1px solid $f-grey-light-border;
		
		@include desktop {
			padding: 20px 0;
			width: 90%;
		}
		
		.checkin, .checkout {
			padding:10px 0;
			margin-bottom:0;
		}
		
		.checkin {
			border-color:$f-grey-light-border;
		}
	}
	
	.weather-widget {
		width: 100%;
		border-top:1px solid $f-grey-light-border;
		border-bottom:1px solid $f-grey-light-border;
		padding:10px 0;
		
		@include desktop {
			padding-top:0;
			border-top:0px;
		}
		
		&__localtime, &__weather {
			padding:10px 0;
			margin-bottom:0;
		}
		
		&__localtime {
			border-color:$f-grey-light-border;
		}
	}

    //Themes for single hotel pages

    &[class*="hotel-"] {

        //Restyle booking mask
        .header-primary.horizon.is-scrolled{
            .header-booking-mask{
                background: $section-white-bg-color;

                .booking-section{

                    .select2-container--anantara .select2-selection--singl, .destination-input-select{
                        background: $f-grey-faint;

                        .select2-selection__rendered, .destination-input-select{
                            color:$t-standard;
                        }

                        .select2-selection__arrow b{
                            border-color:$f-gold transparent transparent transparent;
                        }
                    }

                    .booking-date__wrapper{
                        background: $f-grey-faint;
                        color:$t-standard;
                    }

                    .booking-form-guest-trigger .booking-form-guest-wrapper{
                        background: $f-grey-faint;
                        color:$t-standard;
                    }

                    .promo-btn{
                        color:$t-standard;
                    }

                    .booking-form-label{
                        @include desktop{
                            color:$t-standard;
                        }
                    }

                    .date__group, .booking-form-guest-wrapper{
                        &::after{
                            border-top-color:$f-gold;
                        }
                    }

                    .date__number, .date__month, .date__day{
                        @include desktop{
                            color:$t-standard;
                        }
                    }

                    .booking-form .button__submit{

                        @include desktop{
                            background:$f-gold;
                            border: 1px solid $f-gold;
                        }

                        &:hover{
                            @include desktop{
                                background:transparent;
                                border: 1px solid $f-gold;
                                color:$f-gold;
                            }
                        }
                    }

                    .booking-destination #destination-hotel{
                        background: $f-grey-faint;
                        color:$t-standard;
                    }

                }
            } 
        }
    }

    $brands: (
        'hotel-AN': $color-AN,
        'hotel-AV': $color-AV,
        'hotel-AVP': $color-AVP,
        'hotel-NH': $color-NH,
        'hotel-TI': $color-TI,
        'hotel-OH': $color-OH,
        'hotel-NC': $color-NC,
        'hotel-EW': $color-EW,
        'hotel-NW': $color-NW,
        'hotel-NLD': $color-NLD,
        'hotel-PNIY': $color-PNIY,
        'hotel-ELYW': $color-ELYW
    );
      

    @each $brand, $color in $brands {
        &.#{$brand} {
      
          .header-primary.horizon.is-scrolled {
      
            .header-booking-mask {
              .booking-form .button__submit {
                @include desktop {
                  background: $color;
                  border: 1px solid $color;
                }
      
                &:hover {
                  @include desktop {
                    border: 1px solid $color;
                    color: $color;
                  }
                }
              }
            }
          }
      
          .btn {
            background: $color;
            color: #fff !important;
            border: 1px solid $color;
      
            &:hover {
              background: darken($color, 5%);
              color: #fff !important;
            }
			
			&--ghost {
				background: transparent;
				color: $color !important;
				border: 1px solid $color;
			}
          }
      
          button.slick-arrow {
            background-color: $color;
      
            &.slick-prev:before {
              @include border-right-color(#fff);
            }
      
            &.slick-next:before {
              @include border-left-color(#fff);
            }
          }

          .olapic #olapic-slider-widget .olapic-carousel .olapic-cta-item{
            background-color: $color;
          }
        }
	}
		  
	.olapic-slider-widget {
		.olapic-action-button {
			&.olapic-see-all {
				display:none;
			}
		}
	}
}

#our-people {
    &-profile {
        .profile-img {
            width: 100%;
            max-width: 100%;
            margin-bottom: 2em;
            @include desktop {
                width: 60%;
                max-width: 60%;
            }
        }
        .text-cta {
            $triangle-size: 6px;
            vertical-align: middle;
            margin-top: 2em;

            &:before {
                vertical-align: middle;
                border-top: $triangle-size solid transparent;
                border-bottom: $triangle-size solid transparent;
            }
    
            &:before {
                @include padding-right(.5em);
                @include border-left($triangle-size solid $t-gold);
            }
        }

        &-header {
            .section-header,
            .section-header__text,
            .section-header__title {
                text-align: left;
            }
            .section-header__title {
                font-weight: bold;
                letter-spacing: normal;
                text-transform: none;
                font-size: x-large;
            }
        }
    }
}
